import gitInfo from "../../../../scripts/gitInfo.json";

const GitInfo = () => (
    <div>
        <h2>Git Commit Info</h2>
        <p><strong>Hash:</strong> {gitInfo.commitHash}</p>
        <p><strong>Message:</strong> {gitInfo.commitMessage}</p>
        <p><strong>Date:</strong> {gitInfo.commitDate}</p>
        <p><strong>Branch:</strong> {gitInfo.commitDate}</p>
        <p><strong>Author:</strong> {gitInfo.commitAuthor}</p>
        <p><strong>Author:</strong> {gitInfo.commitBranch}</p>
    </div>
);

export default GitInfo;